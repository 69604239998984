export const timelineDates = [
    { time: '2024-11-01', date: 'November 4, 2024', event: 'Call for abstracts' },
    { time: '2025-01-31',  date: 'January 31, 2025', event: 'Deadline abstract submission' },
    { time: '2025-03-07',  date: 'March 7, 2025', event: 'Abstract acceptance' },
    { time: '2025-03-19',  date: 'March 19, 2025', event: 'Deadline travel grant submission' },
    { time: '2025-04-04', date: 'April 4, 2025', event: 'Deadline early bird registration' },
    { time: '2025-05-11', date: 'May 11, 2025', event: 'Deadline registration' },
    { time: '2025-05-26', date: 'May 26-28, 2025', event: 'SAA conference' },
    { time: '2025-05-29', date: 'May 29, 2025', event: 'Post-conference workshops'}
];
