import React from 'react';
// import { Link } from 'react-router-dom';
import './ComingSoon.css';

const ComingSoon = () => {
    return (
        <div className="App-ComingSoon">
          <header className="ComingSoon-header">
            <div style={{ fontSize: '50px', fontWeight: 'bold' }}>
              Coming soon
            </div>
            <div className='coming-soon-text'>
              <p>Stay informed by subscribing to our mailing list</p>
              <div className="register-button-container">
                <a className="register-button-link" href="https://mailing.kuleuven.be/f-ff9b5871a55176a6"  target="_blank" rel="noopener noreferrer">Subscribe now</a>
              </div>
            </div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
              <p>The full conference program will be online soon. In the meantime, here’s some key information to help you plan your travels:</p>
              <ul style={{ listStyleType: 'none' }}>
                <li>Monday, May 26: Registration opens at 8:00 AM, with the conference kicking off at 9:00 AM.</li>
                <li>Wednesday, May 28: The closing session wraps up at 6:15 PM.</li>
                <li>Thursday, May 29: Post-conference workshops will be held, with the last workshop ending at 6:00 PM.</li>
              </ul>
              <p>We recommend keeping these timings in mind when arranging your arrival and departure.</p>
            </div>
          </header>
        </div>
    );
}

export default ComingSoon;